import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const usePopupMenuStyles = makeStyles((theme: Theme) => ({
  popperRoot: {
    height: '100vh',
    borderRadius: '0',
  },
  root: {
    height: '100%',
    borderRadius: '0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: '1rem',
    // increase specificity to override the default theme, avoiding the need to use !important
    '&.MuiPaper-root.MuiPaper-rounded': {
      backgroundColor: theme.palette.mode === 'light' ? '#424242' : '#404040',
    },
  },
  title: {
    '&.MuiTypography-root': {
      marginLeft: '10px',
      color: '#fff',
      fontSize: '1.5rem',
      marginBottom: '1rem',
    },
  },
  menuList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  option: {
    display: 'flex',
  },
  link: {
    width: '100%',
    color: theme.palette.navigation.color,
    textDecoration: 'none',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    marginLeft: '10px',
  },
  optionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  optionLabels: {
    display: 'flex',
    flexDirection: 'column',
  },
  optionTitle: {
    fontSize: '.875rem',
  },
  optionSubtitle: {
    fontSize: '.75rem',
    maxWidth: '100%',
    '&--wrap': {
      textWrap: 'wrap',
      maxWidth: '100%',
    },
  },
  optionTag: {
    backgroundColor: '#2EFFAF',
    color: '#404040',
  },
}));
